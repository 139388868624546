<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import storedUser from "@/utils/storedUser";

const userError = storedUser().error;
const router = useRouter();

const orgInfo = () => router.push({ path: '/info', query: { tab: 'contact-info' } });
const appeals = () => router.push({ path: '/appeals', query: { tab: 'appeals' } });

const content = ref(null);
const isTilesCentered = ref(false);
// list of tiles; on new tile addition complete the list with additional tile name
const tiles = [
    'orgInfo',
    'appeals'
]

const resize = () => {
  if (content.value) {
    isTilesCentered.value = content.value.clientWidth < 960 || tiles.length < 3;
  }
};

onMounted(() => {
  if (userError) {
    router.push('/login');
  } else {
    resize();
    window.addEventListener('resize', resize);
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', resize);
});
</script>

<template>
  <div class="container content" ref="content">
    <div class="d-flex flex-row justify-content-evenly">
      <div class="row w-100" 
           :class="{'justify-content-between': !isTilesCentered, 'justify-content-evenly': isTilesCentered}">
        <div id="org-contact-tile" 
             class="tile col-auto cursor-pointer border-radius-4 hover-shadow">
          <div class="row h-100">
            <div style="z-index: 1; position: absolute;width: 100%;height: 100%" @click="orgInfo"></div>
            <div class="col-auto d-flex align-items-center">
              <img alt="org-contact-icon.svg" class="img-org-icon" src="../assets/img/icons/icon-org-contact.svg">
            </div>
            <div class="col row align-items-center">
              <a class="col-auto d-flex align-items-center tile-text-1 mt-3">Контактные данные организации</a>
              <div class="col-6">
                <hr class="m-0"/>
              </div>
              <div class="col-9 d-flex align-items-center tile-text-2 text-gray">Цифровое представительство
                Вашей организации
              </div>
              <div class="col-12 d-flex align-items-center tile-text-3" style="height: 35%">
                <ul>
                  <li><a class="text-blue" href="/info?tab=contact-info" style="z-index: 5;position: relative;text-decoration: none;">Контактные данные</a></li>
                  <li><a class="text-blue" href="/info?tab=address" style="z-index: 5;position: relative;text-decoration: none;">Адрес офиса</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="appeal-tile" 
             class="tile col-auto cursor-pointer border-radius-4 hover-shadow">
          <div class="row h-100">
            <div style="z-index: 1; position: absolute;width: 100%;height: 100%" @click="appeals"></div>
            <div class="col-auto d-flex align-items-center">
              <img alt="icon-org-appeal.svg" class="img-appeal-icon col" src="../assets/img/icons/icon-org-appeal.svg">
            </div>
            <div class="col row align-items-center">
              <div class="col-auto d-flex align-items-center tile-text-1 mt-3">Работа с обращениями</div>
              <div class="col-6">
                <hr class="m-0"/>
              </div>
              <div class="col-9 d-flex align-items-center tile-text-2 text-gray">Электронная приемная для работы
                с клиентами
              </div>
              <div class="col-12 d-flex align-items-center tile-text-3" style="height: 35%">
                <ul>
                  <li><a class="text-blue" href="/appeals?tab=appeals" style="z-index: 5;position: relative;text-decoration: none;">Обращения</a></li>
                  <li><a class="text-blue" href="/appeals?tab=archive" style="z-index: 5;position: relative;text-decoration: none;">Архивные обращения</a></li>
                  <li><a class="text-blue" href="/categories" style="z-index: 5;position: relative;text-decoration: none;">Категории и темы</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cursor-pointer{
  cursor: pointer;
}

.hover-shadow{
  -webkit-box-shadow: 0 1px 1px rgba(72,78,85,.6);
  box-shadow: 0 1px 1px rgba(72,78,85,.6);
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.hover-shadow:hover{
  -webkit-box-shadow: 0 4px 8px rgba(72,78,85,.6);
  box-shadow: 0 4px 8px rgba(72,78,85,.6);
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

.content{
  padding-bottom: 100px;
  align-content: center;
}

#tiles {
  display: flex;
  grid-auto-rows: 161px;
  grid-template-columns: 392px 392px;
  grid-gap: 20px;
}

.tile {
  background-color: #FFFFFF !important;
  height: 161px;
  width: 398px;
  position: relative;
  margin-top: 14px;
  margin-bottom: 14px;

  .tile-text-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    text-decoration: none;
    color: #0B0B0B;
  }

  .tile-text-2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  .tile-text-3 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.24px;
    font-feature-settings: 'tnum' on, 'lnum' on;

    color: #008BEA;
  }
}

</style>